import { useState, useEffect, useRef, useContext } from "react";
import { v4 as uuid } from "uuid";

import useAbortSignal from "../hooks/useAbortSignal";
import SingleSelectForm from "./SingleSelectForm";
import asyncAPICall from "../../util/apiWrapper";
import Modal from "../modals/Modal";
import Truncate from "../core/Truncate";

import { errorToast, successfulToast } from "../../util/toastNotifications";
import { todaysFormattedDate } from "../../helpers/todaysFormattedDate";
import { useIssuesData } from "../../context/IssuesContext";
import { camelToTitleCase } from "../../util/stringUtils";
import { me } from "../../DefaultContainer";
import {
  IdbInput,
  IdbTextArea,
  IdbDisabledInput,
  IdbModal,
  IdbSelect,
  IdbCurrency,
} from "../core/IdbInputTypes";

import useCurrencyInput from "../hooks/useCurrencyInput";

const baseSelectedIssueState = {
  job: "",
  jobName: "",
  phaseCode: "",
  issueNumber: "",
  type: "",
  dateRaised: "",
  dcrNumber: "",
  weeksOld: 0,
  title: "",
  description: "",
  notes: "",
  resolutionCost: "",
  scheduleImpact: 0,
  raisedBy: "",
  raisedByName: "",
  owner: "",
  ownerName: "",
  status: "",
  priority: "",
  targetDuration: 0,
  targetDate: "",
  closedDate: "",
  closureComments: "",
  weeksClosed: 0,
  active: true,
  history: [],
};

function IssuesForm(props) {
  const { handleDispatch } = useIssuesData();
  const { setIssuesModal, statusFilter, issueNum, jobNum, jobDesc } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedIssue, setSelectedIssue] = useState(baseSelectedIssueState);
  const [ownerEmail, setOwnerEmail] = useState("");

  let missingFields = false;
  const { signal } = useAbortSignal();
  const { user } = useContext(me);
  const issueBeforeChange = useRef(null);
  const { currentCost, setCurrentCost, handleCostKeyDown } = useCurrencyInput(
    selectedIssue.resolutionCost
  );

  const handleInputChange = (e) => {
    setSelectedIssue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDescription = (desc) => {
    setDescription(desc);
    if (statusFilter !== "resolved" && statusFilter !== "deleted")
      setIsModalOpen(true);
  };

  const handleIssueUpdate = (field, value) => {
    switch (field) {
      case "job":
        setSelectedIssue((prev) => {
          if (prev.job === value.jobNum) {
            return baseSelectedIssueState;
          } else
            return {
              ...prev,
              job: value.jobNum,
              jobName: value.jobName,
            };
        });
        break;

      case "employee":
        setOwnerEmail(value.ownerEmail);
        setSelectedIssue((prev) => {
          if (prev.owner === value.owner) {
            return {
              ...prev,
              owner: "",
            };
          } else {
            return { ...prev, owner: value.owner, ownerName: value.ownerName };
          }
        });
        break;

      case "issueType":
        setSelectedIssue((prev) => {
          if (prev.type === value.type) {
            return {
              ...prev,
              type: "",
            };
          } else {
            return { ...prev, type: value.type };
          }
        });
        break;

      case "phaseCode":
        setSelectedIssue((prev) => {
          if (prev.phaseCode === value.phaseCode) {
            return {
              ...prev,
              phaseCode: "",
            };
          } else {
            return {
              ...prev,
              phaseCode: value.phaseCode,
            };
          }
        });

        break;

      default:
        throw new Error("Handle Issue Update Error");
    }
  };

  const sendOwnerEmail = () => {
    asyncAPICall(
      `api:5e9BgwVw/sendgrid/dynamicEmail`,
      "POST",
      {
        to_email: ownerEmail,
        template_id: "d-2db62e9a0cb94c488959a15ec9f319a4",
        data: {
          date: `${selectedIssue.dateRaised}`,
          jobNumber: `${selectedIssue.job}`,
        },
      },
      (res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
      },
      null,
      (err) => {
        errorToast("Unable to send Issue Email");
        console.error("Unable to send Issue Email: ", err);
      },
      null,
      true
    );
  };

  const handleIssueNotifications = (issueStatus) => {
    setIssuesModal(false);
    successfulToast(` Issues Status Updated to ${issueStatus}`);
    if (
      selectedIssue.owner !== user.employeeID.toString() &&
      selectedIssue.owner !== selectedIssue.raisedBy
    )
      sendOwnerEmail();
  };

  const submitIssueUpdate = (statusType) => {
    let url = "";
    let body;

    if (statusFilter === "new" || statusFilter === "overview") {
      url = "api:W2oHkD04/parextractedissues";

      body = { ...selectedIssue };
    } else {
      url = "api:W2oHkD04/parextractedissuesByIssueNumber";

      body = {
        ...selectedIssue,
        status: statusType,
        closedDate:
          statusType === "Resolved"
            ? todaysFormattedDate()
            : selectedIssue.closedDate,
        history:
          issueBeforeChange.current?.history?.length > 0
            ? [
                ...selectedIssue.history,
                {
                  ...issueBeforeChange.current,

                  dateModified: todaysFormattedDate(),

                  history: [],
                },
              ]
            : [
                {
                  ...issueBeforeChange.current,

                  dateModified: issueBeforeChange.current.dateRaised,

                  history: [],
                },
              ],
      };
    }

    asyncAPICall(
      url,
      "POST",
      body,
      (res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      },
      () => {
        handleIssueNotifications(statusType);
      },
      (err) => {
        errorToast("There was a problem updating your issue");
        console.error("submit issue update error: ", err);
      },
      signal
    );
  };

  const isIssueComplete = (statusType) => {
    let requiredList = [];

    if (statusType === "Active" && statusFilter !== "deleted") {
      requiredList = [
        "job",
        "type",
        "title",
        "description",
        "priority",
        "owner",
      ];
    } else if (statusType === "Resolved") {
      requiredList = [
        "job",
        "jobName",
        "type",
        "title",
        "description",
        "priority",
        "resolutionCost",
        "notes",
        "scheduleImpact",
        "targetDuration",
        "targetDate",
        "owner",
        "closureComments",
      ];
    }

    requiredList.forEach((field) => {
      if (selectedIssue[field] === "") {
        errorToast(`Add ${camelToTitleCase(field)}`);
        missingFields = true;
      }
    });

    return !missingFields;
  };

  const handleSubmitClick = (statusType) => {
    const newIssue = {
      ...selectedIssue,
      status: statusType,
    };

    if (isIssueComplete(statusType)) {
      setSelectedIssue((prev) => {
        return {
          ...prev,
          status: statusType,
        };
      });
      handleDispatch("UPDATE_ISSUE", selectedIssue.issueNumber, newIssue);
      submitIssueUpdate(statusType);
    }
  };

  useEffect(() => {
    const currentUnixDate = Math.floor(Date.now() / 1000);

    if (selectedIssue.dateRaised) {
      const unixRaisedDate =
        new Date(selectedIssue?.dateRaised).getTime() / 1000;
      const daysSinceRaised = Math.floor(
        (currentUnixDate - unixRaisedDate) / 86400
      );

      setSelectedIssue((prev) => ({
        ...prev,
        weeksOld: Math.floor(daysSinceRaised / 7),
      }));
    }

    if (selectedIssue.closedDate) {
      const unixClosedDate =
        new Date(selectedIssue?.closedDate).getTime() / 1000;

      const daysSinceClosed = Math.floor(
        (currentUnixDate - unixClosedDate) / 86400
      );

      setSelectedIssue((prev) => ({
        ...prev,
        weeksClosed: Math.floor(daysSinceClosed / 7),
      }));
    }
  }, [selectedIssue?.dateRaised, selectedIssue?.closedDate]);

  useEffect(() => {
    if (statusFilter === "new" || statusFilter === "overview") {
      setSelectedIssue({
        job: jobNum ? jobNum : "",
        jobName: jobDesc ? jobDesc : "",
        phaseCode: "",
        issueNumber: uuid(),
        type: "",
        dateRaised: todaysFormattedDate(),
        dcrNumber: "",
        weeksOld: 0,
        title: "",
        description: "",
        notes: "",
        resolutionCost: "",
        scheduleImpact: 0,
        raisedBy: `${user.employeeID}`,
        raisedByName: `${user.name}`,
        owner: "",
        ownerName: "",
        status: "Active",
        priority: "",
        targetDuration: 0,
        targetDate: "",
        closedDate: "",
        closureComments: "",
        weeksClosed: 0,
        active: true,
        history: [],
      });
    } else if (issueNum) {
      asyncAPICall(
        `api:W2oHkD04/latestIssueByNumber?issueNumber=${issueNum}`,
        "GET",
        null,
        null,
        (data) => {
          setSelectedIssue(data);
          issueBeforeChange.current = data;
        },
        (err) => {
          if (!signal.aborted) console.error("Error Getting Issue Id", err);
        },
        signal
      );
    }
  }, [signal, issueNum, setSelectedIssue, statusFilter, jobNum, jobDesc, user]);

  useEffect(() => {
    setSelectedIssue((prev) => ({ ...prev, resolutionCost: currentCost }));
  }, [currentCost]);

  return (
    <div className="issues-form-container">
      <div className="issues-header">
        <div className="issues-title">
          <h4 className="issue-number">
            {selectedIssue?.job} {selectedIssue?.jobName}
          </h4>
        </div>

        <div className="close-button">
          <button onClick={() => setIssuesModal(false)}>Close</button>
        </div>
      </div>

      <div className="issues-body">
        <div className="inputs-wrapper">
          <div className="inputs-rows">
            {statusFilter === "overview" ? (
              <IdbModal
                title="Job"
                onClick={() => handleDescription("job")}
                statusFilter={statusFilter}
                value={
                  selectedIssue?.job?.length ||
                  selectedIssue.job === "Select Job" ? (
                    <Truncate content={selectedIssue.job} maxChar={20} />
                  ) : (
                    "Select Job"
                  )
                }
              />
            ) : (
              <IdbDisabledInput
                title="Issue Number"
                value={selectedIssue?.issueNumber}
              />
            )}

            <IdbModal
              title="Phase Code"
              onClick={() => handleDescription("phaseCode")}
              statusFilter={statusFilter}
              value={
                selectedIssue?.phaseCode.length ||
                selectedIssue?.phaseCode === "All Phases"
                  ? selectedIssue?.phaseCode
                  : "All Phases"
              }
            />

            <IdbModal
              title="Issue Type"
              onClick={() => handleDescription("issueType")}
              statusFilter={statusFilter}
              value={
                selectedIssue?.type.length || selectedIssue?.type === "No Type"
                  ? selectedIssue?.type
                  : "No Type"
              }
            />

            <IdbInput
              type="date"
              title="Date Raised"
              name="dateRaised"
              statusFilter={statusFilter}
              disabled={statusFilter !== "new"}
              value={selectedIssue?.dateRaised}
              onChange={handleInputChange}
            />

            <IdbInput
              title="Issue Title"
              name="title"
              statusFilter={statusFilter}
              value={selectedIssue?.title}
              onChange={handleInputChange}
            />

            <IdbDisabledInput
              title="Weeks Old"
              value={selectedIssue?.weeksOld}
            />

            <IdbDisabledInput
              title="Weeks Closed"
              value={selectedIssue?.weeksClosed}
            />

            <IdbDisabledInput
              title="Raised By"
              value={
                <Truncate content={selectedIssue?.raisedByName} maxChar={20} />
              }
            />

            <IdbDisabledInput
              title="Closure Performance"
              value={
                selectedIssue?.targetDuration / selectedIssue?.weeksClosed || 0
              }
            />

            <IdbInput
              title="Closed Date"
              type="date"
              name="closedDate"
              statusFilter={statusFilter}
              value={selectedIssue?.closedDate || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <IdbTextArea
          title="Description"
          name="description"
          statusFilter={statusFilter}
          value={selectedIssue?.description}
          onChange={handleInputChange}
        />

        <div className="status-priority-wrapper">
          <IdbDisabledInput
            title="Status"
            className="disabled dropdown"
            value={selectedIssue?.status}
          />

          <IdbSelect
            title="Priority"
            name="priority"
            statusFilter={statusFilter}
            onChange={handleInputChange}
            value={selectedIssue?.priority}
            options={[
              { value: "", label: "Select Priority" },
              { value: "High", label: "High" },
              { value: "Medium", label: "Medium" },
              { value: "Low", label: "Low" },
            ]}
          />
        </div>

        <div className="cost-inputs-wrapper">
          <div className="inputs-rows">
            <IdbCurrency
              title="Resolution Cost"
              currencySign="$"
              statusFilter={statusFilter}
              value={selectedIssue.resolutionCost}
              onKeyDown={handleCostKeyDown}
              onChange={(e) => setCurrentCost(e.target.value)}
            />

            <IdbInput
              subHeader="(Calendar Days)"
              title="Schedule Impact"
              name="scheduleImpact"
              statusFilter={statusFilter}
              value={selectedIssue?.scheduleImpact}
              onChange={handleInputChange}
            />

            <IdbInput
              subHeader="(wks)"
              title="Target Duration"
              name="targetDuration"
              statusFilter={statusFilter}
              value={selectedIssue?.targetDuration}
              onChange={handleInputChange}
            />

            <IdbInput
              type="date"
              title="Target Date"
              name="targetDate"
              statusFilter={statusFilter}
              value={selectedIssue?.targetDate}
              onChange={handleInputChange}
            />

            <IdbModal
              title="Issue Owner"
              onClick={() => handleDescription("employee")}
              statusFilter={statusFilter}
              value={
                <Truncate
                  content={
                    selectedIssue?.ownerName?.length
                      ? selectedIssue?.ownerName
                      : "Select Owner"
                  }
                  maxChar={20}
                />
              }
            />
          </div>
        </div>

        <IdbTextArea
          title="Action Required / Notes"
          name="notes"
          statusFilter={statusFilter}
          value={selectedIssue?.notes}
          onChange={handleInputChange}
        />

        <IdbTextArea
          title="Closure Comments"
          name="closureComments"
          statusFilter={statusFilter}
          value={selectedIssue?.closureComments}
          onChange={handleInputChange}
        />
      </div>

      <div className="issues-footer">
        <div className="submit-buttons-wrapper">
          {(statusFilter === "pending" || statusFilter === "active") && (
            <button
              className="active-button"
              onClick={() => {
                handleSubmitClick("Active");
              }}
            >
              {statusFilter === "pending" ? "Activate" : "Update"}
            </button>
          )}

          <button
            className="revise-button"
            onClick={() => {
              const newStatus =
                statusFilter === "pending"
                  ? "Deleted"
                  : statusFilter === "active"
                  ? "Resolved"
                  : "Active";
              handleSubmitClick(newStatus);
            }}
          >
            {statusFilter === "pending"
              ? "Delete"
              : statusFilter === "active"
              ? "Resolve"
              : "Activate"}
          </button>
        </div>
      </div>

      <Modal
        isModalOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        content={{ borderRadius: "0px", overflow: "hidden" }}
      >
        <SingleSelectForm
          selectedIssue={selectedIssue}
          description={description}
          setIsModalOpen={setIsModalOpen}
          handleIssueUpdate={handleIssueUpdate}
        />
      </Modal>
    </div>
  );
}

export default IssuesForm;
