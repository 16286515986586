import { useState, useEffect } from "react";

import LoadingSpinner from "../../helpers/LoadingSpinner";
import useAbortSignal from "../hooks/useAbortSignal";
import asyncAPICall from "../../util/apiWrapper";
import useDebounce from "../hooks/useDebounce";
import SearchBox from "../core/SearchBox";
import Truncate from "../core/Truncate";

import { camelToTitleCase } from "../../util/stringUtils";

const SingleSelectForm = (props) => {
  const { setIsModalOpen, selectedIssue, description, handleIssueUpdate } =
    props;

  const [data, setData] = useState([]);
  const [noDataMsg, setNoDataMsg] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const searchValue = useDebounce(searchInput);

  const { signal } = useAbortSignal();

  const renderModalData = () => {
    if (filteredData.length > 0) {
      return filteredData.map((issue) => {
        const findDescription = () => {
          switch (description) {
            case "job":
              return {
                jobNum: `${issue.Project}`,
                jobName: `${issue.Project_Name}`,
                dataTitle: `${issue.Project} ${issue.Project_Name}`,
              };

            case "employee":
              return {
                owner: `${issue.Approver}`,
                ownerName: `${issue.FullName}`,
                dataTitle: `${issue.FullName}`,
              };

            case "issueType":
              return {
                type: `${issue.type}`,
                dataTitle: `${issue.type}`,
              };

            case "phaseCode":
              return {
                phaseCode: `${issue.Phase} ${issue.Description}`,
                dataTitle: `${issue.Phase} ${issue.Description}`,
              };

            default:
              return null;
          }
        };
        const descriptionClassName = () => {
          return (
            selectedIssue !== undefined &&
            ((selectedIssue.phaseCode.trim() ===
              `${issue.Phase} ${issue.Description}`.trim() &&
              description === "phaseCode") ||
              (selectedIssue.type === issue?.type &&
                description === "issueType") ||
              (selectedIssue.job === issue.Project && description === "job") ||
              (selectedIssue.owner === issue.Approver &&
                description === "employee"))
          );
        };

        return (
          <div
            key={issue.id}
            data-title={findDescription().dataTitle}
            className={`item ${descriptionClassName() ? "" : "not-"}selected`}
            onClick={() => {
              description === "job"
                ? handleIssueUpdate(description, {
                    jobNum: issue.Project,
                    jobName: issue.Project_Name,
                  })
                : description === "employee"
                ? handleIssueUpdate(description, {
                    owner: issue.Approver,
                    ownerName: issue.FullName,
                    ownerEmail: issue.Email,
                  })
                : description === "issueType"
                ? handleIssueUpdate(description, { type: issue.type })
                : handleIssueUpdate(description, {
                    phaseCode: `${issue.Phase} ${issue.Description}`.trim(),
                  });
            }}
          >
            {description === "phaseCode" ? (
              <Truncate
                content={`${issue.Phase} ${issue.Description}`}
                maxChar={20}
              />
            ) : description === "issueType" ? (
              <Truncate content={issue.type} maxChar={20} />
            ) : description === "job" ? (
              <Truncate
                content={`${issue.Project} ${issue.Project_Name}`}
                maxChar={20}
              />
            ) : description === "employee" ? (
              <Truncate content={`${issue.FullName}`} maxChar={20} />
            ) : (
              ""
            )}
          </div>
        );
      });
    }
  };

  useEffect(() => {
    const apiEndpoint = () => {
      switch (description) {
        case "issueType":
          return "api:W2oHkD04/issues_type";

        case "job":
          return `api:KE7MCNYf/vprojectswithid`;

        case "employee":
          return `api:W2oHkD04/vmultipleapproversbyjob?job=${selectedIssue.job}`;

        case "phaseCode":
          return `api:KE7MCNYf/vprojectphasesbyproject?jobNumber=${selectedIssue.job}`;

        default:
          return null;
      }
    };

    asyncAPICall(
      apiEndpoint(),
      "GET",
      null,
      null,
      (data) => {
        setData(data);
        setIsLoading(false);
        if (data.length === 0) {
          setNoDataMsg(true);
        } else {
          setNoDataMsg(false);
        }
      },
      (err) => {
        console.error("Getting Data Error", err);
      },
      signal
    );
  }, [signal, selectedIssue.job, description]);

  useEffect(() => {
    const checkForMatch = (itemObject) => {
      const searchObject = () => {
        switch (description) {
          case "job":
            return (
              itemObject.Project.trim().includes(searchValue) ||
              itemObject.Project_Name.toLowerCase()
                .trim()
                .includes(searchValue.toLowerCase())
            );

          case "employee":
            return itemObject.FullName.toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase());

          case "issueType":
            return itemObject.type
              .toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase());

          case "phaseCode":
            return (
              itemObject.Phase.trim().includes(searchValue) ||
              itemObject.Description.toLowerCase()
                .trim()
                .includes(searchValue.toLowerCase())
            );

          default:
            return null;
        }
      };

      return searchObject();
    };
    setFilteredData(data.filter(checkForMatch));
  }, [searchValue, data, description]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <div className="header-container">
          <h1>{camelToTitleCase(description)}</h1>

          <SearchBox onChange={(e) => setSearchInput(e.target.value)} />
        </div>

        <hr />

        <div
          className={`main-content-container ${isLoading ? "spinner" : ""} ${
            noDataMsg ? "no-data" : ""
          }`}
        >
          {isLoading ? <LoadingSpinner /> : renderModalData()}
          {noDataMsg && (
            <div className="no-data-msg-wrapper">
              <div className="no-data-msg">{`No ${camelToTitleCase(
                description
              )}s`}</div>
            </div>
          )}
        </div>

        <button onClick={() => setIsModalOpen(false)}>Save</button>
      </div>
    </div>
  );
};

export default SingleSelectForm;
